<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle title="Add Holiday" slot="title" link="/helpContent/addHoliday" />
 
    <ValidationObserver ref="validator">
      <FormRow>
        <div class="col-sm-6">
          <inputDate label="Date" v-model="form.date" rules="required" />
        </div>
        <div class="col-sm-6">
          <InputText 
            label="Occasion"
            v-model="form.occasion "
            rules="required"
          />
        </div>

      </FormRow>
    </ValidationObserver>
    <br />
    <FormRow>
      <div class="col-sm-6">
        <ButtonGroup>
          <FormButton type="info" @click="()=>{$router.go(-1)}">Back</FormButton>
          <FormButton type="success" @click="sendData()">Save</FormButton>
        </ButtonGroup>
      </div>
    </FormRow>
    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
      <div slot="footer">
        
      <FormButton
        
        @click="()=>{$router.go(-1)}"
          >Ok</FormButton
        ></div>
    </Popup>
    <Popup
      title="Information"
      type="info"
      :value="infoMessage.isVisible"
      @close="() => (infoMessage.isVisible = false)"
      :closable="true"
    >
      {{ infoMessage.message }}
    </Popup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import loadingMixin from "Mixins/loadingMixin";
import { addHoliday } from "../api";
import InputDate from "Components/form/InputDate";
import { ValidationObserver } from "vee-validate";

export default {
  name: "add",
  mixins: [loadingMixin],
  components: {
    InputDate,
    FormWrapper,
    PageTitle,
    ValidationObserver,
  },
  data() {
    return {
      showMessage: {
        isVisible: false,
      },
      infoMessage: {
        isVisible: false,
      },
      form: {
        date: "",
        occasion : "",
      },
    };
  },
  created() {
  },
  methods: {
    sendData() {
      this.$refs.validator.validate().then(result=>{
        if(result){
          this.showMessage.isVisible = false;
          this.infoMessage.isVisible = false;
          const data = {
            date: this.form.date,
            occasion : this.form.occasion,
          };

          addHoliday(data).then((res) => {
              if(res.data.status==1){
                this.form = {};
                this.showMessage.isVisible = true;
                this.showMessage.message = res.data.message;
              }else{
                this.infoMessage.isVisible = true;
                this.infoMessage.message = res.data.message;
              }
          });
        }
      })
    },
  },
};
</script>

